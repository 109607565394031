import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import format from "@app/lib/format";
import pkg from "@app/../package.json";
import { observer } from "mobx-react";
import { Button, Tooltip, Popover } from "antd";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { CopyOutlined, HighlightOutlined, BookOutlined, CommentOutlined } from "@ant-design/icons";
import { default as BookmarkModal } from "@app/components/document/bookmark/modal";
import { default as HighlightModal } from "@app/components/highlight/modal";
import { default as CommentsModal } from "@app/components/comments/modal";
import { renderToolbar } from "./toolbar";
import { ReportDocumentSection } from "@app/constants";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import "./style/pdf-viewer.scoped.scss";
import useSelection from "./useSelection";

import { highlightPlugin } from "./plugins/highlight/highlight-plugin";
import PulseCircle from "./plugins/highlight/pulse-circle";

export const PdfViewer = observer(
    ({
        fileId,
        goto,
        renderPage,
        highlights = [],
        onBookmark,
        onAddHighlight,
        onComment,
        preventCopy,
    }) => {
        const { project, document: documentId } = useParams();

        if (!highlights.length && goto) {
            highlights.push({ areas: [{ ...goto, isPoint: true }] });
        }

        const version = pkg.dependencies["pdfjs-dist"];
        const fileUrl = format.file(fileId);
        const [action, setAction] = useState(null);
        const toggleAction = useCallback((action, toggle) => {
            setAction(action);
            toggle();
        }, []);

        const defaultLayout = defaultLayoutPlugin({
            sidebarTabs: (defaultTabs) => [defaultTabs[0]],
            renderToolbar,
        });

        // Sanitize the annotations to remove invalid break characters
        const { onAnnotationLayerRender } = defaultLayout;
        defaultLayout.onAnnotationLayerRender = function (props) {
            if (props.annotations) {
                for (const anno of props.annotations) {
                    try {
                        document.querySelector('a[attr="'.concat(anno.dest, '"]'));
                    } catch (ex) {
                        anno.dest = anno.dest.replace(/\s+/g, "_");
                    }
                }
            }

            onAnnotationLayerRender(props);
        };

        const selectedText = useSelection();

        const [doc, setDoc] = useState(null);
        const [highlight, setHighlight] = useState(null);
        const [bookmark, setBookmark] = useState(null);
        const [comment, setComment] = useState(null);

        const highlighting = highlightPlugin({
            renderHighlightTarget({ cancel, selectionRegion, toggle, highlightAreas }) {
                const onCopy = async (e) => {
                    const data = {};

                    data["text/plain"] = new Blob([selectedText], { type: "text/plain" });

                    const area = JSON.stringify(highlightAreas[0]);

                    if (documentId && project) {
                        const sourceDocumentLinkTag =
                            ReportDocumentSection.CAPTIS_LINK.SOURCE_DOCUMENT_LINK;

                        data["text/html"] = new Blob(
                            [
                                `<${sourceDocumentLinkTag} project=${project} documentId=${documentId} area=${area}>${selectedText}</${sourceDocumentLinkTag}>`,
                            ],
                            { type: "text/html" },
                        );
                    } else {
                        const articleTextTag = ReportDocumentSection.ARTICLE_TEXT.ARTICLE_TEXT;

                        data["text/html"] = new Blob(
                            [
                                `<${articleTextTag} project=${project} area=${area}>${selectedText}</${articleTextTag}>`,
                            ],
                            { type: "text/html" },
                        );
                    }

                    navigator.clipboard.write([new window.ClipboardItem(data)]).then(cancel);
                };

                const { isPoint } = highlightAreas[0];

                return (
                    <div
                        className={"tooltip-container"}
                        style={{
                            left: `${selectionRegion.left}%`,
                            top: `${selectionRegion.top + selectionRegion.height}%`,
                        }}
                    >
                        {!(isPoint || preventCopy) && (
                            <Tooltip title={"Copy"} placement={"bottom"} mouseEnterDelay>
                                <Button type={"text"} icon={<CopyOutlined />} onClick={onCopy} />
                            </Tooltip>
                        )}
                        {onAddHighlight && (
                            <Tooltip title={"Highlight"} placement={"bottom"} mouseEnterDelay>
                                <Button
                                    type={"text"}
                                    onClick={() => toggleAction("highlight", toggle)}
                                    icon={<HighlightOutlined />}
                                />
                            </Tooltip>
                        )}
                        {onBookmark && (
                            <Tooltip title={"Bookmark"} placement={"bottom"} mouseEnterDelay>
                                <Button
                                    type={"text"}
                                    onClick={() => toggleAction("bookmark", toggle)}
                                    icon={<BookOutlined />}
                                />
                            </Tooltip>
                        )}
                        {onComment && (
                            <Tooltip title={"Comment"} placement={"bottom"} mouseEnterDelay>
                                <Button
                                    type={"text"}
                                    onClick={() => toggleAction("comment", toggle)}
                                    icon={<CommentOutlined />}
                                />
                            </Tooltip>
                        )}
                    </div>
                );
            },
            renderHighlightContent({ cancel, selectionRegion, selectedText, highlightAreas }) {
                cancel();

                if (action === "highlight") {
                    setHighlight({
                        content: selectedText,
                        areas: highlightAreas,
                        source: "reactPdfViewer",
                    });
                }

                if (action === "bookmark") {
                    setBookmark({
                        areas: highlightAreas,
                    });
                }

                if (action === "comment") {
                    setComment({
                        areas: highlightAreas,
                        quotedText: selectedText,
                    });
                }

                setAction(null);
            },
            renderHighlights({ pageIndex, rotation, getCssProperties }) {
                return (
                    <div>
                        {highlights.map((highlight) => {
                            if (!highlight.areas) {
                                return null;
                            }

                            if (
                                highlight.areas.length === 1 &&
                                highlight.areas[0].isPoint &&
                                highlight.areas[0].pageIndex === pageIndex
                            ) {
                                return (
                                    <React.Fragment key={highlight._id}>
                                        <PulseCircle
                                            style={getCssProperties(highlight.areas[0], rotation)}
                                        />
                                    </React.Fragment>
                                );
                            }

                            const popoverTitle = highlight?.author?.name;
                            const popoverText = highlight?.content;

                            return (
                                <React.Fragment key={highlight._id}>
                                    {highlight.areas
                                        .filter((area) => area.pageIndex === pageIndex)
                                        .map((area, index) => {
                                            if (popoverText) {
                                                return (
                                                    <Popover
                                                        title={popoverTitle}
                                                        key={`${highlight._id}-popover-${index}`}
                                                        placement={"top"}
                                                        content={() => (
                                                            <div
                                                                className={
                                                                    "pdf-viewer-popover-content"
                                                                }
                                                            >
                                                                {popoverText?.length > 256
                                                                    ? `${highlight.content.slice(
                                                                          0,
                                                                          256,
                                                                      )}...`
                                                                    : popoverText}
                                                            </div>
                                                        )}
                                                    >
                                                        <div
                                                            className={"highlight"}
                                                            style={{
                                                                ...getCssProperties(area, rotation),
                                                            }}
                                                        />
                                                    </Popover>
                                                );
                                            } else {
                                                return (
                                                    <div
                                                        className={"highlight"}
                                                        key={`${highlight._id}-${index}`}
                                                        style={{
                                                            ...getCssProperties(area, rotation),
                                                        }}
                                                    />
                                                );
                                            }
                                        })}
                                </React.Fragment>
                            );
                        })}
                    </div>
                );
            },
        });
        const { jumpToHighlightArea } = highlighting;

        useEffect(() => {
            if (doc && goto) {
                jumpToHighlightArea(goto);
            }
        }, [doc, goto]);

        useEffect(() => {
            // Stub effect. mobx needs to know we are watching something about this state to get update events
        }, [highlights.length]);

        // Fixed height required for viewer. See https://github.com/react-pdf-viewer/react-pdf-viewer/issues/775
        return (
            <div className="pdf-viewer">
                <Worker
                    workerUrl={`https://unpkg.com/pdfjs-dist@${version}/build/pdf.worker.min.js`}
                >
                    <Viewer
                        key={fileId}
                        fileUrl={fileUrl}
                        plugins={[defaultLayout, highlighting]}
                        onDocumentLoad={(ev) => setDoc(ev.doc)}
                        renderPage={renderPage}
                    />
                </Worker>
                <HighlightModal
                    visible={!!highlight}
                    highlight={highlight}
                    onSave={(hl) => {
                        setHighlight(null);
                        onAddHighlight && onAddHighlight(hl);
                    }}
                    onDiscard={() => {
                        setHighlight(null);
                    }}
                />
                <BookmarkModal
                    visible={!!bookmark}
                    bookmark={bookmark}
                    onSave={(data) => {
                        setBookmark(null);
                        onBookmark && onBookmark(data);
                    }}
                    onDiscard={() => {
                        setBookmark(null);
                    }}
                />
                <CommentsModal
                    modalOpen={comment}
                    setModalOpen={() => setComment(null)}
                    onSubmitHandler={(data) => {
                        onComment && onComment({ ...data, ...comment });
                        setComment(null);
                    }}
                />
            </div>
        );
    },
);

export default PdfViewer;
