// React and related imports
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { observer } from "mobx-react";

// Third-party libraries
import { Button, Tooltip } from "antd";
import { CopyOutlined, BookOutlined } from "@ant-design/icons";

// App-specific imports
import format from "@app/lib/format";
import { ReportDocumentSection } from "@app/constants";
import bookmarksStore from "@app/state/store/report/document/bookmarks";
import { default as BookmarkModal } from "@app/components/document/bookmark/modal";
import PulseCircle from "@app/components/pdf-viewer/plugins/highlight/pulse-circle";
import notify from "@app/components/notify";

// Local imports
import BookmarkTab from "./bookmark-tab";

// Styles
import "./style/preview.scoped.scss";

const HighlightTarget = ({ left, top, showActions, onCopyLink, onAddAsBookmark }) => {
    const targetRef = useRef(null);

    useEffect(() => {
        if (!showActions && targetRef.current) {
            setTimeout(() => {
                if (targetRef.current) {
                    targetRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
                }
            }, 100);
        }
    }, [left, top, showActions, targetRef.current]);

    return (
        <div
            className={"target-container"}
            style={{
                left: `${left}%`,
                top: `${top}%`,
            }}
        >
            <PulseCircle style={{ top: 0, left: 0 }} ref={targetRef} />

            {showActions && (
                <div className="tooltip-container">
                    <Tooltip title={"Copy"} placement={"bottom"} mouseEnterDelay>
                        <Button type={"text"} icon={<CopyOutlined />} onClick={onCopyLink} />
                    </Tooltip>
                    {onAddAsBookmark && (
                        <Tooltip title={"Bookmark"} placement={"bottom"} mouseEnterDelay>
                            <Button
                                type={"text"}
                                onClick={onAddAsBookmark}
                                icon={<BookOutlined />}
                            />
                        </Tooltip>
                    )}
                </div>
            )}
        </div>
    );
};

const useSetPositionFromUlr = ({ bookmarks, setHighlightPosition, setShowTargetWithActions }) => {
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const area = searchParams.get("area");
    const bid = searchParams.get("bid");

    useEffect(() => {
        if (area) {
            const parsedArea = JSON.parse(area);

            setHighlightPosition({
                left: parsedArea.left,
                top: parsedArea.top,
            });
            setShowTargetWithActions(false);
        }
        if (bid) {
            const selectedBookmark = bookmarks.find((b) => b._id === bid);
            if (selectedBookmark && selectedBookmark.areas && selectedBookmark.areas.length > 0) {
                setHighlightPosition({
                    left: selectedBookmark.areas[0].left,
                    top: selectedBookmark.areas[0].top,
                });
                setShowTargetWithActions(false);
            }
        }
    }, [area, bid, setHighlightPosition, bookmarks]);
};

const ImagePreview = observer(({ file, document, onBookmark }) => {
    const bookmarks = bookmarksStore?.bookmarks || [];
    const imgRef = useRef(null);
    const [showTargetWithActions, setShowTargetWithActions] = useState(false);
    const [highlightPosition, setHighlightPosition] = useState({ left: 0, top: 0 });

    const [bookmark, setBookmark] = useState(null);
    const location = useLocation();
    const history = useHistory();

    useSetPositionFromUlr({
        bookmarks,
        setHighlightPosition,
        setShowTargetWithActions,
    });

    if (!file.isImage || !document.file?._id) {
        return null;
    }

    const onRightClick = useCallback(
        (e) => {
            e.preventDefault();

            const imageElement = e.target;
            const imageRect = imageElement.getBoundingClientRect();
            const x = e.clientX - imageRect.left;
            const y = e.clientY - imageRect.top;

            const relativeX = (x / imageRect.width) * 100;
            const relativeY = (y / imageRect.height) * 100;

            history.replace({
                pathname: location.pathname,
                search: "",
            });

            setHighlightPosition({
                left: relativeX,
                top: relativeY,
            });
            setShowTargetWithActions(true);
        },
        [location, history],
    );

    const onClick = useCallback(() => {
        const searchParams = new URLSearchParams(location.search);
        if (!searchParams.get("area") && !searchParams.get("bid") && !bookmark) {
            setHighlightPosition({});
        }
        setShowTargetWithActions(false);
    }, [location.search, bookmark]);

    const onAddAsBookmark = () => {
        setBookmark({
            areas: [
                {
                    top: highlightPosition.top,
                    left: highlightPosition.left,
                    isPoint: true,
                },
            ],
        });
        setShowTargetWithActions(false);
    };

    const onCopyLink = async () => {
        const data = {};
        const selectedText = "";

        data["text/plain"] = new Blob([selectedText], { type: "text/plain" });

        const area = JSON.stringify({
            top: parseFloat(highlightPosition.top),
            left: parseFloat(highlightPosition.left),
            isPoint: true,
        });

        const sourceDocumentLinkTag = ReportDocumentSection.CAPTIS_LINK.SOURCE_DOCUMENT_LINK;

        data["text/html"] = new Blob(
            [
                `<${sourceDocumentLinkTag} project=${document.project} documentId=${document._id} area=${area}></${sourceDocumentLinkTag}>`,
            ],
            { type: "text/html" },
        );

        try {
            await navigator.clipboard.write([new window.ClipboardItem(data)]);
            notify.info("Link copied to clipboard");
        } catch (error) {
            notify.error("Failed to copy link to clipboard");
        }

        setShowTargetWithActions(false);
    };

    const showHighlightTarget =
        typeof highlightPosition.left === "number" && typeof highlightPosition.top === "number";

    return (
        <div className="image-preview" onClick={onClick}>
            <div className="image-container" style={{ display: "inline-block" }}>
                <img
                    src={format.image(document.file._id)}
                    ref={imgRef}
                    onContextMenu={onRightClick}
                    style={{ display: "block", width: "100%" }}
                />
                {showHighlightTarget && (
                    <HighlightTarget
                        left={highlightPosition.left}
                        top={highlightPosition.top}
                        showActions={showTargetWithActions}
                        onCopyLink={onCopyLink}
                        onAddAsBookmark={onAddAsBookmark}
                    />
                )}
                <BookmarkModal
                    visible={!!bookmark}
                    bookmark={bookmark}
                    onSave={(data) => {
                        setBookmark(null);
                        onBookmark && onBookmark(data);
                    }}
                    onDiscard={() => {
                        setBookmark(null);
                    }}
                />
                {bookmarks
                    .filter((bookmark) => bookmark?.document === document._id)
                    .map((bookmark) => {
                        return <BookmarkTab key={bookmark._id} bookmark={bookmark} />;
                    })}
            </div>
        </div>
    );
});

export default ImagePreview;
