import React, { forwardRef } from "react";

import "./pulse-circle.scoped.scss";

export const PulseCircle = forwardRef(({ style = {} }, ref) => {
    const { top, left, bottom, right } = style;

    return <div ref={ref} className="pulse-circle" style={{ top, left, bottom, right }}></div>;
});

export default PulseCircle;
