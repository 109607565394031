import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import { scrollToComment, scrollToSelection } from "@app/components/report-document/editor/utils";

const useFocusUrlComment = ({ commentsState }) => {
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const params = qs.parse(location.search, { ignoreQueryPrefix: true });
        const commentId = params.comment;
        const hasComments = commentsState.comments.length > 0;

        if (hasComments && commentId && commentId !== commentsState.focusedComment) {
            params.comment = commentId;

            history.replace({
                pathname: location?.pathname,
                search: "?" + qs.stringify(params, { encodeValuesOnly: true, indices: false }),
            });

            setTimeout(async () => {
                if (commentsState.focus) {
                    commentsState.focus(commentId);
                }

                scrollToComment(commentId);
                scrollToSelection(commentId);

                const comment = commentsState.comments.find((comment) => comment._id === commentId);
                if (comment) {
                    comment.expanded = true;
                }
            }, 500);
        }
    }, [location, commentsState.comments, commentsState.focusedComment]);
};

export default useFocusUrlComment;
